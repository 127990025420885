import {
    ProviderOAuthType,
} from '~/app/shared/enums/authentication/authentication-provider.enum';
import { ProviderType } from '~/app/shared/types/provider/provider.type';

export abstract class OAuthProvider {
    private _providerName: ProviderType;

    protected constructor(name: ProviderOAuthType) {
        this._providerName = name ?? ProviderOAuthType.NONE;
    }

    get providerName(): ProviderType {
        return this._providerName;
    }

    set providerName(value: ProviderType) {
        this._providerName = value;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    abstract getLogoutUrl(isUserInternal?: boolean): string;

    abstract getLoginUrl(accountServicerId?: string): string;
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Envestboard API
 * ## Implementation details

 * Every string passed to and from the API must be UTF-8 encoded
 * Date and Datetime are formatted according to standard ISO-8601
 * Default timezone is UTC
 * Currency code are formatted according to standard ISO-4217
 * Language code are formatted according to standard ISO-639-1
 * Country code are formatted according to standard ISO-3166 alpha-3
 * Zone and continent are formatted with a custom 3-10 characters code
 * Unless specified otherwise, all API methods require authentication

## Authentication and authorization

**Authentication** is available from the specif endpoint. With the correct credentials, you get an access token with a very short lifetime and a refresh token used to recreate the access token when it expires. The refresh token can only be used once.<br />
**Authorization** is based on the jwt token in the header obtained from the authentication process when you call the REST APIs.

## Pagination

We uses keyset (cursor) pagination to limit the response size for resources that return a potentially large collection of items.
To browse and retrieve the different result pages, you must provide the last id of the previous page in the `startAt` parameter or field in the request.
A request to a paged API will result in a values array wrapped in a JSON object with some paging metadata.

Example :
```
{
  "values": [
    ...
  ],
  "number": 0,
  "size": 0,
  "totalNumber": 0,
  "startAt": 0,
}
```

 * OpenAPI spec version: 1.8.1-SNAPSHOT
 */

/**
 * Tenant authentication type
 */
export type AuthenticationModelType = typeof AuthenticationModelType[keyof typeof AuthenticationModelType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthenticationModelType = {
  INTERNAL: 'INTERNAL',
  OAUTH: 'OAUTH',
} as const;
